import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./actualNews.css";
import TitleBar from "../titleBar/TitleBar";

const API_BASE_URL = "https://api.sigortagundem.com";

const NewsPage = () => {
  const categories = [
    { id: "Bes - Hayat", name: "Bes - Hayat" },
    { id: "Kasko - Trafik", name: "Kasko - Trafik" },
    { id: "Kulis", name: "Kulis" },
    { id: "Gündem", name: "Gündem" },
    { id: "Elementer", name: "Elementer" },
    { id: "Foto Galeri", name: "Foto Galeri" },
    { id: "Dünya", name: "Dünya" },
  ];

  const [articles, setArticles] = useState([]);
  const [activeCategory, setActiveCategory] = useState(categories[0].id);
  const [currentPage, setCurrentPage] = useState(0);
  const [fade, setFade] = useState(true);
  const [featuredArticle, setFeaturedArticle] = useState({
    title: "",
    category: "",
    image: "",
  });
  const itemsPerPage = 4;
  const navigate = useNavigate();

  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${API_BASE_URL}?limit=1000`);
        if (Array.isArray(response.data.news)) {
          setArticles(response.data.news);
        } else {
          console.error("API'den dönen veri dizi değil:", response.data);
        }
      } catch (error) {
        console.error("Error fetching news:", error);
      }
    };

    fetchArticles();
  }, []);

  const handlePrev = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage > 0
          ? prevPage - 1
          : Math.ceil(filteredArticles.length / itemsPerPage) - 1
      );
      setFade(true);
    }, 500);
  };

  const handleNext = () => {
    setFade(false);
    setTimeout(() => {
      setCurrentPage((prevPage) =>
        prevPage < Math.ceil(filteredArticles.length / itemsPerPage) - 1
          ? prevPage + 1
          : 0
      );
      setFade(true);
    }, 500);
  };

  const handleCategoryClick = (categoryId) => {
    setActiveCategory(categoryId);
    setCurrentPage(0);
  };

  const handleNewsClick = async (newsId, slug, event) => {
    event.preventDefault();
    try {
      // Increment view count
      await axios.put(`${API_BASE_URL}/${newsId}/increment-view`);

      // Navigate to news article
      navigate(`/${slug}`);
    } catch (error) {
      console.error("Error incrementing view count:", error);
      // Navigate to news article even if there's an error
      navigate(`/${slug}`);
    }
  };

  const filteredArticles = articles.filter(
    (article) => article.categories.includes(activeCategory) && !article.isDraft
  );

  const startIndex = currentPage * itemsPerPage;
  const currentArticles = filteredArticles.slice(
    startIndex,
    startIndex + itemsPerPage
  );

  useEffect(() => {
    if (filteredArticles.length > 0) {
      setFeaturedArticle(filteredArticles[0]);
    }
  }, [activeCategory, filteredArticles]);

  const handleArticleClick = (article) => {
    setFeaturedArticle(article);
  };

  return (
    <div className="news-page mt-4">
      <div className="header">
        <div className="dont-miss">KATEGORİLER</div>
        <nav className="categories-nav-list">
          {categories.map((category) => (
            <button
              key={category.id}
              className={category.id === activeCategory ? "active" : ""}
              onClick={() => handleCategoryClick(category.id)}
            >
              {category.name}
            </button>
          ))}
        </nav>
      </div>

      <div className="content lg:grid grid-cols-2">
        <div
          className="featured-article cursor-pointer"
          style={{
            backgroundImage: `url(${featuredArticle.image})`,
          }}
          onClick={(e) =>
            handleNewsClick(featuredArticle._id, featuredArticle.slug, e)
          }
        >
          <div className="featured-title">{featuredArticle.title}</div>
        </div>
        <div className={`article-list ${fade ? "fade-in" : "fade-out"}`}>
          <div className="row h-full">
            <div className="col-md-8 articles-content">
              {currentArticles.map((article, index) => (
                <div
                  key={index}
                  className="article grid grid-cols-3 gap-2 cursor-pointer"
                  onClick={(e) => {
                    handleArticleClick(article);
                    handleNewsClick(article._id, article.slug, e);
                  }}
                >
                  <img
                    src={article.image}
                    alt={"img " + index}
                    className="article-thumbnail col-span-1"
                  />
                  <div className="article-info col-span-2">
                    <p>{article.title}</p>
                    
                  </div>
                </div>
              ))}
            </div>
            <div className="col-md-2 m-auto">
              <div className="navigation navigation-desktop">
                <span className="nav-button-left" onClick={handlePrev}>
                <span class="material-symbols-outlined">
keyboard_arrow_up
</span>
                </span>
                <span className="nav-button-right" onClick={handleNext}>
                <span class="material-symbols-outlined">
keyboard_arrow_down
</span>
                </span>
              </div>
              <div className="navigation navigation-mobile">
                <span className="nav-button-left" onClick={handlePrev}>
                  <span class="material-symbols-outlined">
chevron_left
</span>
                </span>
                <span className="nav-button-right" onClick={handleNext}>
                  <span class="material-symbols-outlined">
chevron_right
</span>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NewsPage;