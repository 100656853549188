import React, { useEffect, useState } from "react";
import "./right.css";

const AdContainer = ({ ad }) => {
  const handleAdClick = async () => {
    if (ad && ad._id) {
      try {
        await fetch(`https://api.sigortagundem.com/api/ads/${ad._id}/hit`, {
          method: "POST",
        });
      } catch (error) {
        console.error("Hit sayısı artırılırken hata oluştu:", error);
      }
    }
  };

 
  const isIframeAd = ad.imageURL.includes("doubleclick.net") || ad.imageURL.includes("sadbundle");

  return (
    <div
      className="ad-container"
      onClick={handleAdClick} 
    >
      {ad.link ? (
        <a href={ad.link} target="_blank" rel="noopener noreferrer">
          {isIframeAd ? (
            <iframe
              src={ad.imageURL}
              width="160px"
              height="600px"
              frameBorder="0"
              scrolling="no"
              style={{ border: "0", verticalAlign: "bottom" }}
              allowFullScreen={ad.imageURL.includes("sadbundle")}
              title={ad.title}
            ></iframe>
          ) : (
            <img
              src={ad.imageURL}
              alt={ad.title}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          )}
        </a>
      ) : (
        <div>
          {isIframeAd ? (
            <iframe
              src={ad.imageURL}
              width="160px"
              height="600px"
              frameBorder="0"
              scrolling="no"
              style={{ border: "0", verticalAlign: "bottom" }}
              allowFullScreen={ad.imageURL.includes("sadbundle")}
              title={ad.title}
            ></iframe>
          ) : (
            <img
              src={ad.imageURL}
              alt={ad.title}
              style={{ width: "100%", height: "100%", objectFit: "contain" }}
            />
          )}
        </div>
      )}
    </div>
  );
};

const AdsRight = () => {
  const [ads, setAds] = useState([]);
  const [currentAdIndex, setCurrentAdIndex] = useState(0);

  useEffect(() => {
    fetch("https://api.sigortagundem.com/api/ads")
      .then((response) => response.json())
      .then((data) => {
        const validTitles = [
          "Anasayfa Sağ DİKEY Reklam",
          "Anasayfa Sağ DİKEY Reklam 1",
          "Anasayfa Sağ DİKEY Reklam 2",
          "Anasayfa Sağ DİKEY Reklam 3",
          "Anasayfa Sağ DİKEY Reklam 4",
          "Anasayfa Sağ DİKEY Reklam 5",
        ];
        const filteredAds = data.filter(
          (ad) => validTitles.includes(ad.title) && ad.isActive === true
        );
        setAds(filteredAds);
      })
      .catch((error) => {
        console.error("Reklam verileri çekilirken hata oluştu:", error);
      });
  }, []);

  useEffect(() => {
    if (ads.length > 1) {
      const interval = setInterval(() => {
        setCurrentAdIndex((prevIndex) => (prevIndex + 1) % ads.length);
      }, 5000); // 5 saniyede bir değişim
      return () => clearInterval(interval);
    }
  }, [ads]);

  if (ads.length === 0) {
    return null;
  }

  return (
    <div className="vertical-fixed-right adss">
      <AdContainer ad={ads[currentAdIndex]} />
    </div>
  );
};

export default AdsRight;
