import React, { useEffect, useRef } from "react";

const AdContainer = ({ ads, currentAdIndex, handleAdClick }) => {
  const adRef = React.useRef(null);

  React.useEffect(() => {
    if (ads.length === 0 || !ads[currentAdIndex]) return;

    const currentAd = ads[currentAdIndex];
    adRef.current.innerHTML = ""; // Clear previous content

    if (
      currentAd.imageURL.includes("doubleclick.net") ||
      currentAd.imageURL.includes("sadbundle")
    ) {
      const iframe = document.createElement("iframe");
      iframe.src = currentAd.imageURL;
      iframe.style =
        "width: 100%; height: 100%; border: 0; vertical-align: bottom;";
      iframe.frameBorder = "0";
      iframe.scrolling = "no";
      iframe.allowFullscreen = true;
      adRef.current.appendChild(iframe);
    } else {
      const img = document.createElement("img");
      img.src = currentAd.imageURL;
      img.alt = currentAd.title;
      img.style =
        "width: 100%; height: 100%; object-fit: contain; cursor: pointer;";
      img.onclick = () => handleAdClick(currentAd);
      adRef.current.appendChild(img);
    }
  }, [ads, currentAdIndex, handleAdClick]);

  return (
    <div className="advertisement" style={{ width: "100%", height: "70px" }}>
      <div ref={adRef} style={{ width: "100%", height: "100%" }}></div>
    </div>
  );
};

export default AdContainer;
